@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

/* GENERAL CSS */

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

/*old body:

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100vh;
}

old html:

html {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100vh;
  height: 100vh;
}
*/

.bg {
  background-repeat: no-repeat;
  background-position: center;
  height: 100lvh;
  width: 100vw;
  position: fixed;
  z-index: -50;

}



footer {
  font-family: 'DM Sans', sans-serif;
  font-size: 8pt;
  font-weight: 500;
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: -5;
}

.content {
    position: absolute;
    margin-top: 60px; /* Adjust margin to the height of your navbar */
}

.homeParagraph {
  text-align: center;
  font-size: 14pt;
}

.otherParagraph {
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    text-indent: 1em;
    margin: 25px auto 0; /* 20px top margin, center horizontally */
    max-width: 85%;
}

.SportsDataProject {
  height: 100lvh; /* Adjust height as needed */
}

p {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
}

.intro-p {
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    text-indent: 1em;
    margin: 25px auto 0; /* 20px top margin, center horizontally */
    max-width: 85%;
}

ul {
    margin-left: 80px;
}

/* NAVBAR CSS */

.navbar {
    display: flex;
    position: fixed;
    height: 40px;
    top: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(to right, #4162b0, #266C87, #3c588c, #512cb0);
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    z-index: 1000; /* Corrected property name */
}

.header-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 26px;
    user-select: none;
    margin: 0;
    color: black;
}

.header-title-container {
    position: relative; /* This makes the container a positioned ancestor */
    height: 24px; /* Adjust the height as needed */
    width: 160px;
    margin-left: 6px;
}

.header-title-name {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    user-select: none;
    margin: 0;
    color: black;
    position: absolute;
    bottom: 0; /* Sticks the text to the bottom */
}

.navbar-left {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.navbar-right {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.color-blend-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px; /* Adjust as needed */
  background-image: linear-gradient(to right, #4162b0, #266C87, #3c588c, #512cb0);
  border-top: 1px solid black;
  border-bottom: 2px solid black;
}


/* HAMBURGER MENU CSS */

.hamburger-menu {
    position: relative;
    font-size: 17px;
    margin-right: 15px; /* Change margin-right to margin-left */
    border: black 1.5px solid;
    border-radius: 3px;
    background: #6666ff;
    color: black;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    z-index: 1000;
}

.hamburger-menu::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #8a8aff;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    z-index: -1;
}

.hamburger-menu:focus {
    outline: none;
}

.hamburger-menu:hover::before {
    opacity: 1;
}

.menu {
    position: absolute;
    margin-right: -15px; /* Change margin-left to margin-right */
    top: 60px;
    right: -375px; /* Change left to right */
    background-color: white;
    color: black;
    min-width: 280px; /* Change width to min-width */
    transition: right 0.3s ease-in-out; /* Change left to right */
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    border: 1.5px solid black;
    user-select: none;
    z-index: 10;
}

.menu.open {
    right: 0; /* Change left to right */
}

.menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0; /* Add margin to remove default margin */
}

.menu li {
    padding: 6px;
    text-align: left;
    margin-left: 10px;
    transition: transform 0.2s ease;
}

.menu li:first-child {
    margin-top: 7px;
}

.menu li:last-child {
    margin-bottom: 7px;
}

.menu li:hover {
    transform: scale(1.05); /* Scale up by 5% */
}

.menu a,
.menu button {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    color: black;
    text-decoration: none;
    border-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    position: relative;
    z-index: 1;
    font-size: 16px;
    transition: transform 0.2s ease; /* Add transition property */
}

.menu button {
    background-color: transparent;
    border: none;
    outline: none;
}

.menu a:hover {
    transform: scale(1.45); /* Increase size by 5% on hover */
}



.ScrollableContainer {
  height: 100lvh; /* Set a fixed height for the container */
  overflow-y: auto; /* Allow vertical overflow */
}

.AlongTheWay {
  display: flex;
  flex-direction: column; /* Stack images vertically */
  align-items: center; /* Center images horizontally */
}

.AlongTheWay p {
  padding-left: 18px;
  padding-right: 18px;
}

.image {
  margin-top: 15px;
  max-width: 90%; /* Set maximum width for the images */
  width: auto; /* Allow images to adjust width proportionally */
  margin-bottom: 5px; /* Add margin between images */
  border: 1px solid black;
}

.image:first-child {
  margin-top: 50px;
}

.image:last-child {
  margin-bottom: 150px;
}

.bottom-margin {
  margin-bottom: 100px; /* Adjust margin for the bottom image */
}

@media only screen and (max-width: 768px) {
    /* Adjustments for smaller screens (e.g., phones) */

    .resume-image {
        width: 95vw; /* Use viewport width for mobile view */
    }

    ul {
      margin-left: 30px;
    }

    .resume-wrapper {
        width: 95%; /* Adjust width to take full width in mobile view */
        max-width: none; /* Remove max-width for mobile view */
        margin: 50px auto;
    }

  .navbar {
    align-items: center;
    width: 100vw;
    height: 7lvh;
    background-size: 100% 100%;
  }

  .navbar-right {
    padding: 0;
  }

  .menu {
    width: 180px;
    top: 85px;
  }

  .hamburger-menu {
    font-size: 18pt;
  }

  .homeParagraph {
    text-align: justify;
    font-size: 13pt;
  }

  .header-title {
    font-size: 24px;
  }

  .header-title-container {
      position: relative; /* This makes the container a positioned ancestor */
      height: 24px; /* Adjust the height as needed */
      width: 160px;
      margin-left: 6px;
  }

  .header-title-name {
      font-family: 'DM Sans', sans-serif;
      font-weight: 500;
      font-size: 17px;
      user-select: none;
      margin: 0;
      color: black;
      position: absolute;
      bottom: 0; /* Sticks the text to the bottom */
  }

  .intro-p {
    font-size: 20px;
  }

  intro-p:first-child {
    margin-top: 20px;
  }

  .lineupGroup p {
    font-size: 8pt;
  }

}

.Home {
  margin-top: 5em;
  display: flex-top;
  justify-content: center;
  align-items: center;
  height: 75vh; /* Set the height of the container to the full viewport height */
}



.Projects {
  margin-top: 7em;
}

.projectsParagraph {
  text-align: center;
}

.Contact {
  margin-top: 7em;
}

.contactParagraph {
  text-align: center;
}

.homeParagraph {
  text-align: center;
}

.resume-page-container {
    max-height: 100vh;
    overflow-y: auto;
}

.download-pdf-container {
    text-align: center;
    margin-top: 70px; /* Adjust margin as needed */
}

.resume-image {
    width: 100%; /* Ensure images take full width of their container */
    height: auto;
    border: 1px solid black;
    margin-bottom: 10px;
    margin-top: 5px;
}

.resume-wrapper {
    display: flex; /* Use flexbox to align images side by side */
    flex-wrap: wrap; /* Allow images to wrap to the next line if needed */
    justify-content: center; /* Center images horizontally */
    margin-top: 20px;
}

.resume-image-container {
    flex: 1; /* Each image container takes equal width */
    margin-left: 15px;
    margin-right: 15px; /* Adjust spacing between images */
    margin-bottom: 80px; /* Adjust spacing between rows of images */
}

.downloadPDF {
    font-size: 16px;
}

.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease; /* Ensure smooth transition */
}

.mlbLinkButton {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  text-decoration: none;
  user-select: none; /* Non-prefixed version, currently supported by most browsers */
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 40px;
  margin-top: 40px;
  border: 2px solid black; /* Ensure border is transparent */
  border-radius: 5px;
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: rgba(51, 95, 166, 0.7); /* Transparent blue background */
  backdrop-filter: blur(8px); /* Apply a blur filter */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition for background and border color */

  /* Additional styles for better visibility */
  color: black; /* Text color */
  width: 85%; /* Set width to 85% of the viewport */
  max-width: 300px; /* Optionally set a maximum width */
  box-sizing: border-box; /* Ensure padding and border are included in width calculation */
  display: block; /* Make it a block element to take full width */
  margin-left: auto; /* Center the button horizontally */
  margin-right: auto;
}

.mlbLinkButton:hover {
  background-color: rgba(51, 95, 166, 0.9); /* Lighter transparent blue on hover */
  border: 2px solid black; /* Ensure border is transparent */
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

