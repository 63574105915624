.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow-y: hidden;
  transform: scale(1.0);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.mlb-data-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  overflow-y: auto;
  position: relative;
  color: white;
  background-color: #545454;
  background-image: radial-gradient(circle at 11% -69%, #0000004b 75%, transparent 70.05%),radial-gradient(circle at -17% -80%, #00000017 49%, transparent 49.05%),radial-gradient(circle at 72% -30%, #0000001c 44%, transparent 44.05%),linear-gradient(-323deg, #00000017 44%, transparent 44.05%);
}

.game-container {
  background-color: rgba(41, 41, 41, 0.85);
  border: 1.5px solid #555555;
  border-radius: 6px;
  margin-bottom: 10px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 300;
  width: 95%;
}

.game-container:first-child {
  margin-top: 10px;
}

.game-data-container {
  background-color: rgba(70, 70, 70, 0.8);
  border: 2px solid #555555;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.stat-toggle-container {
  max-height: 164px;
  overflow: hidden;
}

.game-data-container:first-child {
  margin-top: 10px;
}

.game-data-container:last-child {
  margin-bottom: 10px;
}

.scoreboard-row {
  display: flex;
  width: 100%;
}

.scoreboard-row:first-child {
  font-size: 3pt !important;
}

.scoreboard-cell {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px; /* Ensure all cells have the same height */
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.scoreboard-cell.inning, .scoreboard-cell.runs, .scoreboard-cell.hits, .scoreboard-cell.errors {
  flex: 1; /* Ensures that these cells also have the same flex value */
  font-size: 10pt;
}

/* Optional: if you want to control the width of specific columns */
.scoreboard-cell.team-abbr {
  flex: 2; /* Adjust as needed */
  font-size: 10pt;
}

.team-abbr {
  font-weight: bold;
}

.matchup-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensure it takes the full width */
}

.matchup-container {
  display: flex;
  flex-direction: column; /* Change to column layout */
  align-items: center; /* Center items horizontally */
  width: 100%;

}

.game-data {
  display: flex;
  border: 1px solid #555555;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center; /* Ensure text is centered */
}

.game-data-title {
  font-size: 14px!important;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center; /* Ensure text is centered */
}

.column2 p, .column2 span, .column2 div {
  font-size: 16px; /* Change this value to your desired font size */
}

.matchup-group {
  display: flex;
  justify-content: center; /* Ensure space between columns */
  min-height: 130px;
  border: 1px solid #555555;
  padding: 5px; /* Add padding for better spacing */
  padding-top: 0px;
  padding-bottom: 0px;
}

.custom-datepicker-input {
  border-radius: 5px;
  background-color: rgba(70, 70, 70, 0.95);
  cursor: pointer;
  font-size: 1.1em;
  font-family: 'DM Sans', sans-serif;
  font-weight: 300;
  color: inherit;
  user-select: none;
  margin: 0; /* Remove margin to prevent shifting */
}

.custom-datepicker-input .arrow {
  margin-left: 0.5em;
  display: inline-block;
  transition: transform 0.5s ease;
}

.custom-datepicker-input .arrow.open {
  transform: rotate(180deg);
}

.custom-datepicker-input .arrow.closed {
  transform: rotate(0deg);
}

.custom-datepicker-input .react-datepicker {
  background-color: rgba(70, 70, 70, 0.95);
}

.custom-datepicker-input .react-datepicker__header {
  background-color: rgba(70, 70, 70, 0.95);
}

.react-datepicker__month-container {
  border-radius: 5px;
  border: none;
}
.custom-datepicker-input .react-datepicker__current-month,
.custom-datepicker-input .react-datepicker__day,
.custom-datepicker-input .react-datepicker__day-name,
.custom-datepicker-input .react-datepicker-time__header {
  color: white;
  border: none;
  font-size: 18px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 300;
}

.custom-datepicker-input .react-datepicker__day--today {
  color: gold; /* Make the text color gold for today's date */
}

h2 {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 10px;
  border: 2.5px solid black;
  border-radius: 5px;
  background-color: rgba(70, 70, 70, 0.8);
  padding: 5px 15px;
}

h3 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 3px;
  text-align: center;
  background-color: rgba(70, 70, 70, 0.8);
  border-radius: 5px;
}

.column1, .column2, .column3 {
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

.column1 {
  width: 10%; /* Take 10% of the width */
}

.column1 .row1, .column1 .row2 {
  flex: 1; /* Divide the space equally between row1 and row2 */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.row2 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.column2 {
  width: 20%; /* Take 20% of the width */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.column3 {
  width: 60%; /* Take 60% of the width */
}

.pitchingColumn {
  min-width: 80%;
  max-width: 90%;
  height: 100%;
  margin-bottom: 30px;
}

.column1 img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain; /* Ensures the image scales down to fit within its container */
  margin-right: 15px;
  border: 1.5px solid #555555;
  background-color: rgba(70, 70, 70, 0.8);
  border-radius: 7px;
  padding: 5px;
  opacity: .85;
}

.pitcher-info-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
  margin-bottom: -2px;
  min-height: 68px;
}

.pitcher-info-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-bottom: auto;
  min-height: 72px;
}

.pitcher-details {
  text-align: center;
}

.vs p {
  font-size: 12px;
}

.dropdown-menu {
  margin-top: 5px;
  max-width: 250px;
  display: inline-block;
}

select {
  width: auto; /* Allows the select to take its natural width */
  max-width: 97.5%; /* Prevents it from taking up the full width */
  padding: 4px;
  margin: 4px;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  border-radius: 4px;
  border: 2px solid #555555;
  color: white;
  background-color: rgba(78, 78, 78, 0.9);
  cursor: pointer;
  text-align: center; /* Centers the text horizontally */
  display: inline-block;
}

select:focus {
  outline: none;
}

.game-time {
  font-size: 19px;
  margin: 0px;
}

.game-time-container {
  background-color: rgba(70, 70, 70, 0.8);
  display: flex;
  justify-content: center;
}

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.5);
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust height as needed */
  width: 100%;
  font-variant: small-caps;
  z-index: 10; /* Ensure it is on top of other elements */
  position: relative;
  background-color: rgba(41, 41, 41, 1);
}

.loading-background {
  background-color: rgba(41, 41, 41, 1);
}

.loading img {
  width: 80px; /* Adjust the size as needed */
  height: 80px; /* Adjust the size as needed */
  animation: zoomInOut 1.9s infinite; /* Apply the zoom in/out animation */
}

.mlbDataNavbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.noGames {
  font-size: 20px;
  margin-bottom: 0px;
  border: 1.5px solid #555555;
  border-radius: 5px;
  background-color: rgba(70, 70, 70, 0.8);
  padding: 5px 15px;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.loading p {
  margin-top: 0px; /* Space between the image and text */
  font-size: 25px; /* Adjust the font size as needed */
  color: white; /* Adjust the text color as needed */
  animation: fadeInOut 1.9s infinite; /* Apply the fade in/out animation */
  margin-top: -10px;
  margin-left: 7px;
}

.last-twenty {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.last-twenty-column {
  min-width: 60px;
  flex: 1 0 20%;
  padding: 3px 6px;
  text-align: center;
  box-sizing: border-box;
  font-size: 11px;
}

.last-twenty-row {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}

.last-twenty-row.date {
  justify-content: center;
}

.team-row {
  display: flex;
  flex-wrap: nowrap;
}

.scroll-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: auto; /* Single scrollbar */
  white-space: nowrap;
}

.team-cell {
  flex: 1;
  text-align: left;
  padding-left: 2px;
}

.score-cell {
  flex: 1;
  text-align: right;
  padding-right: 2px;
}

.last-twenty-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

.team-and-score-group {
  outline: 1.5px solid #555555;
  border-radius: 3px;
  padding: 1px 3.3px;
  width: 49px;
}

.games-container {
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap;
  display: flex;
  flex-direction: column;
}

.games-container:active {
  cursor: grabbing;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in forwards; /* Adjust the duration as needed */
}

.fade-out {
  animation: fadeOut 1s ease-out;
  opacity: 0; /* Ensure the element becomes transparent at the end of the animation */
  transition: opacity 1s ease-out;
}

.fade-in-background {
  background-color: rgba(41, 41, 41, 1);
  opacity: 0;
  transition: opacity 1s ease-in;
  z-index: -1;
}

.fade-in-background.loaded {
  opacity: 1;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls button {
  box-shadow: 1px 2px 2px 0px black;
  border: 1px solid black;
  font-size: 15pt;
  padding: 2px 5px;
}

.teams-button {
  font-size: 1.2em;
  margin-right: 10px;
}

.teams-menu {
  font-size: 18px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 300;
  position: absolute;
  top: 128px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(70, 70, 70, 0.95);
  padding: 10px;
  border: 0.1px solid white;
  border-radius: 5px;
  z-index: 1000;
  width: 250px;
}

.menu-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;
}

.select-button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 300;
  background-color: rgba(85, 85, 85, 0.95);
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  padding: 5px 25px;
}

.team-stats {
  font-size: 12.2px;
  text-align: left;
  margin-left: 15px;
}

.close-button {
  margin-left: auto;
}

.teams-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
}

.teams-menu li {
  margin: 5px 0;
  text-align: left; /* Align the list items to the left */
}

.teams-menu label {
  display: flex;
  align-items: center;
}

.teams-menu input[type="checkbox"] {
  margin-right: 10px;
}

/* Add these styles to your MLBData.scss or a separate stylesheet */
@keyframes flashText {
  0% {
    color: yellow;
  }
  100% {
    color: white;
  }
}

.flash-text {
  animation: flashText 1s ease-out;
}

.team-pick-container {
  display: flex;
  justify-content: center; /* Center the images in the div */
  margin-top: 10px;
}

.team-pick {
  outline: 2px solid #555555;
  border-radius: 3px;
  display: inline-block;
  width: 60px; /* Adjust the width as needed */
  height: 60px; /* Adjust the height as needed */
  cursor: pointer;
  text-align: center;
  line-height: 60px; /* Vertically center the image */
  margin-right: 50px; /* Adjust margin to control spacing */
  margin-bottom: 10px;
}

.team-pick img {
  width: 80%; /* Adjust the percentage as needed to make the logo smaller */
  height: auto; /* Maintain the aspect ratio */
  vertical-align: middle; /* Vertically center the image */
}

.team-pick:last-child {
  margin-right: 0; /* Remove margin from the last item */
}

.guess-results {
  background-color: rgba(41, 41, 41, 0.94);
  border: 1.5px solid #555555;
  border-radius: 6px;
  margin-bottom: 10px;
  width: 95%;
}

.guess-results p {
  font-size: 10pt;
  margin: 5px 0px;
}


@media only screen and (max-width: 768px) {
  .column1 {
    width: 25%; /* Take 25% of the width */
    padding: 5px;
  }

  .column2 {
    width: 75%; /* Take 75% of the width */
  }

.column2 .pitcher-details {
  font-size: 13px; /* Adjust the font size */
}

  .column3 {
    display: none; /* Hide column3 on mobile devices */
  }

  .column1 img {
    margin-right: 0px;
    margin-top: 5px;
  }

  p.vs {
    font-size: 12px;
    margin: 0px;
    margin-bottom: -13px;
  }
}